<template>
  <div class="auth">
    <!-- <img src="~assets/img/auth/bg-1.png" class="bg"> -->
    <transition name="auth-router" mode="out-in">
      <router-view class="router" />
    </transition>
  </div>
</template>

<script>
export default {
  name: "auth",
};
</script>

<style scoped>
header {
  height: auto;
  background: linear-gradient(left, #83cc8c, #7ac690);
}
header /deep/ .van-nav-bar {
  background: transparent;
  line-height: 2.5;
}
header /deep/ .van-nav-bar__content {
}
header /deep/ .van-nav-bar__title {
  color: #fff;
  font-size: 1.2rem;
}
header /deep/ .van-nav-bar__arrow {
  color: #fff;
  font-size: 2rem;
}
header /deep/ .van-nav-bar__left {
  position: relative !important;
}
header /deep/ .van-hairline--bottom::after {
  display: none;
}

.auth {
  padding-top: 46px;
  min-height: 120vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #84cd8c),
    color-stop(100%, #4ca7a3)
  );
  background: gradient(
    linear,
    left top,
    right bottom,
    color-stop(0%, #84cd8c),
    color-stop(100%, #4ca7a3)
  );
}
</style>
